import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import ApiService from "@/core/services/ApiService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "sign-in",
    component: () => import("@/components/page-layouts/SignupBackground.vue"),
    children: [
      {
        path: "/sign-up",
        name: "signupForm",
        component: () => import("@/views/SignupForm.vue"),
      },
      {
        path: "/forgot-password",
        name: "forgotPassword",
        component: () => import("@/views/ForgotPassword.vue"),
      },
      {
        path: "/validate-email",
        name: "validateEmail",
        component: () => import("@/views/EmailValidationStaticPage.vue"),
      },
      {
        path: "/welcome",
        name: "afterProcessPayment",
        component: () => import("@/views/AfterProcessingPaymentPage.vue"),
      },
      {
        path: "/reset-password/:secret",
        name: "resetPassword",
        component: () => import("@/views/ResetPasswordForm.vue"),
      },
      {
        path: "/set-password/:secret",
        name: "setPassword",
        component: () => import("@/views/SetUpPasswordForm.vue"),
      },
      {
        path: "/step-form",
        name: "stepForm",
        component: () => import("@/views/StepForm.vue"),
      },
    ],
  },
  {
    path: "/",
    redirect: "/sign-in",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
    ],
  },
  {
    path: "/",
    redirect: "/clients/provisioning",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/clients",
        name: "clients",
        component: () => import("@/views/Clients.vue"),
      },
      {
        path: "/bd-clients",
        name: "bd-clients",
        component: () =>
          import("@/components/customers/cards/overview/BdClients.vue"),
      },
      {
        path: "/inventory/SIM-cards",
        name: "sim-cards",
        component: () =>
          import("@/components/customers/cards/overview/SIMCards.vue"),
      },
      {
        path: "/inventory/devices",
        name: "bd-devices",
        component: () =>
          import("@/components/customers/cards/overview/AlarmDevices.vue"),
      },
      {
        path: "/clients/provisioning",
        name: "provisioning-clients",
        component: () => import("@/views/Customers.vue"),
      },
      {
        path: "/clients/provisioning/:id/:showComponent",
        name: "provisioning-clients-detail",
        component: () => import("@/views/apps/customers/CustomerDetails.vue"),
      },
      {
        path: "/dashboard/:id/:showComponent",
        name: "customer-dashboard",
        component: () => import("@/views/apps/customers/CustomerDetails.vue"),
      },
      {
        path: "/clients/provisioning/create/:customerId/:type",
        name: "new-provisioning-clients",
        component: () => import("@/views/UpdateProvisioningClientForm.vue"),
      },
      {
        path: "/client/:id",
        name: "client-single",
        component: () => import("@/views/ClientSingle.vue"),
      },
      {
        path: "/clients/provisioning/update/:customerId/:residentId",
        name: "edit-provisioning-clients",
        component: () => import("@/views/UpdateProvisioningClientForm.vue"),
      },
      {
        path: "/clients/provisioning/update/:residentId",
        name: "edit-provisioning-client",
        component: () => import("@/views/UpdateProvisioningClientForm.vue"),
      },
      {
        path: "/individual-provisioning-group/data-entry",
        name: "individual-provisioning-data-entry",
        component: () => import("@/views/DataEntryClients.vue"),
      },
      {
        path: "/individual-provisioning-group/awaiting-payment",
        name: "individual-provisioning-awaiting-payment",
        component: () => import("@/views/AwaitingPaymentClients.vue"),
      },
      {
        path: "/individual-provisioning-group/device-configuration",
        name: "individual-provisioning-device-configuration",
        component: () => import("@/views/DeviceConfigClients.vue"),
      },
      {
        path: "/individual-provisioning-group/awaiting-dispatch",
        name: "individual-provisioning-awaiting-dispatch",
        component: () => import("@/views/AwaitingDispatchClients.vue"),
      },
      {
        path: "/facility-provisioning-group/data-entry",
        name: "provisioning-data-entry",
        component: () => import("@/views/DataEntryFacilityClients.vue"),
      },
      {
        path: "/facility-provisioning-group/awaiting-payment",
        name: "facility-provisioning-awaiting-payment",
        component: () => import("@/views/AwaitingPaymentFacilityClients.vue"),
      },
      {
        path: "/facility-provisioning-group/device-configuration",
        name: "facility-provisioning-device-configuration",
        component: () => import("@/views/DeviceConfigFacilityClients.vue"),
      },
      {
        path: "/facility-provisioning-group/awaiting-dispatch",
        name: "facility-provisioning-awaiting-dispatch",
        component: () => import("@/views/AwaitingDispatchFacilityClients.vue"),
      },
      {
        path: "/billing-group/overdue-invoices",
        name: "overdue-invoices",
        component: () => import("@/views/OverdueCustomers.vue"),
      },
      {
        path: "/billing-group/failed-transactions",
        name: "failed-transaction-invoices",
        component: () => import("@/views/FailedTransactionCustomers.vue"),
      },
      {
        path: "/billing-group/expried-credit-cards",
        name: "expired-credit-card",
        component: () => import("@/views/ExpiredCreditCardCustomers.vue"),
      },
      {
        path: "/invoices",
        name: "invoices-list",
        component: () => import("@/views/Invoices.vue"),
      },
      {
        path: "/devices",
        name: "devices",
        component: () => import("@/views/Devices.vue"),
      },
      {
        path: "/groups/villages",
        name: "groups-villages",
        component: () => import("@/views/Village.vue"),
      },
      {
        path: "/groups/villages/:id",
        name: "groups-villages-single",
        component: () => import("@/views/VillageSingle.vue"),
      },
      {
        path: "/groups/organisations",
        name: "groups-organisations",
        component: () => import("@/views/Organization.vue"),
      },
      {
        path: "/billing-config/independent",
        name: "billing-config-independent",
        component: () => import("@/views/IndependentBillingConf.vue"),
      },
      {
        path: "/billing-config/village",
        name: "billing-config-village",
        component: () => import("@/views/VillageBillingConf.vue"),
      },
      {
        path: "/billing",
        name: "billing",
        component: () => import("@/views/Billings.vue"),
      },
      {
        path: "/xero/contacts",
        name: "xero",
        component: () => import("@/views/Xero.vue"),
      },
      {
        path: "/provisioning",
        name: "provisioning",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/admin/users",
        name: "adminUser",
        component: () => import("@/views/UserManagement.vue"),
      },
      {
        path: "/admin/groups",
        name: "adminGroup",
        component: () => import("@/views/UserGroups.vue"),
      },
      {
        path: "/admin/accesses",
        name: "adminAccess",
        component: () => import("@/views/UserAccesses.vue"),
      },
      {
        path: "/admin/tools",
        name: "adminTool",
        component: () => import("@/views/ToolsTable.vue"),
      },
      {
        path: "/admin/subscription",
        name: "adminSubscription",
        component: () => import("@/views/SubscriptionManagement.vue"),
      },
      {
        path: "/admin/recurring-history",
        name: "adminRecurringHistory",
        component: () => import("@/views/RecurringHistoryTable.vue"),
      },
      {
        path: "/admin/logs",
        name: "adminLogs",
        component: () => import("@/views/HistoryTable.vue"),
      },
      {
        path: "/admin/subscription/rosiePlanItems/:rosiePlanId",
        name: "adminRosiePlan",
        component: () => import("@/views/RosiePlanItemsTable.vue"),
      },
      {
        path: "/user/profile",
        name: "userProfile",
        component: () => import("@/components/page-layouts/Profile.vue"),
      },
      {
        path: "/manager/user",
        name: "managerUser",
        component: () => import("@/components/widgets/tables/AllUserTable.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.beforeEach(() => {
  store.commit(Mutations.RESET_LAYOUT_CONFIG);
  if (JwtService.getToken() !== null) {
    ApiService.setHeader();
    store.dispatch(Actions.VERIFY_AUTH);
    const currentStatus = store.getters.currentStatus;
    if (
      currentStatus !== undefined &&
      currentStatus.startsWith("ACTIVE") &&
      window.location.hash === "#/step-form"
    ) {
      window.location.href = "/";
    } else if (
      currentStatus !== undefined &&
      currentStatus.startsWith("AWAITING_SETUP") &&
      window.location.hash !== "#/step-form"
    ) {
      const { href } = router.resolve({
        name: "stepForm",
      });
      window.location.assign(href);
    }
  }
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

router.afterEach(() => {
  store.dispatch(Actions.RENEW_AUTH);
});

export default router;
